<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'SEO Page'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'SEO Page Create'"
      :title="'SEO Page Create'"
    >
      <div class="form-create__buttons">
        <MainButton buttonWhite @click.native="handleSubmit">Submit</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="form-create">
        <form @submit.prevent="handleSubmit">
          <p class="form-create__label">URL</p>
          <div class="input-container">
            <InputText
              :onChange="setSeoName"
              :id="'url'"
              :label="'url'"
              :value="formDataToSend.url"
              :type="'text'"
              :placeholder="'URL'"
              :error-messages="validationErrors['url']"
              isBordered
            />
          </div>

          <p class="form-create__label">Title</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'title'"
              :label="'title'"
              :type="'text'"
              :placeholder="'Title'"
              :error-messages="validationErrors['title']"
              isBordered
            />
          </div>

          <p class="form-create__label">Description</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'description'"
              :label="'description'"
              :type="'text'"
              :placeholder="'Description'"
              :max-length="'255'"
              :error-messages="validationErrors['description']"
              isBordered
            />
          </div>

          <p class="form-create__label">H1</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'h1'"
              :label="'h1'"
              :type="'text'"
              :placeholder="'H1'"
              :error-messages="validationErrors['h1']"
              isBordered
            />
          </div>

          <p class="form-create__label">Annotation</p>
          <div class="input-container">
            <InputTextarea
              :set-value="formDataToSend"
              :id="'annotation'"
              :label="'annotation'"
              :placeholder="'Annotation'"
              :error-messages="validationErrors['annotation']"
              isBordered
            />
          </div>

          <p class="form-create__label">SEO Text</p>
          <div class="input-container">
            <TextEditor
              :set-value="formDataToSend"
              :id="'seo_text'"
              :placeholder="''"
              :error-messages="validationErrors['seo_text']"
            />
          </div>
          <div class="input-container">
            <MainCheckbox :label="'Active'" :id="'active'" :set-value="formDataToSend" />
          </div>

          <div class="input-container">
            <MainCheckbox
              :label="'Noindex ATTENTION!!!!!!'"
              :id="'noindex'"
              :set-value="formDataToSend"
            />
          </div>
          <div v-if="error.message != undefined" class="form-create__error">
            {{ error.message }}
          </div>
          <div class="form-create__btn">
            <MainButton :type="'submit'">Submit</MainButton>
          </div>
        </form>
        <ModalError
          :error-modal="false"
          :handle-close-error-modal="
            () => {
              this.error = {};
            }
          "
        />
      </div>
    </ContentContainer>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import InputTextarea from "~/components/atoms/inputs/InputTextarea.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import MainCheckbox from "~/components/atoms/inputs/MainCheckbox.vue";
import TextEditor from "~/components/atoms/inputs/TextEditor.vue";

export default {
  name: "SeoCreate",
  metaInfo: {
    title: "SEO Page Create",
  },
  data() {
    return {
      formDataToSend: {
        h1: "",
        url: "",
        seo_text: "",
        active: false,
        noindex: false,
        annotation: "",
        title: "",
        description: "",
      },
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    InputText,
    ModalError,
    InputTextarea,
    MainCheckbox,
    TextEditor,
  },
  mounted() {},
  computed: {
    ...mapState("statics", ["error", "loadingProcess", "validationErrors"]),
    getFirstLink() {
      return ROUTE.SEO_LIST.replace(":page", 1);
    },
  },
  methods: {
    handleSubmit() {
      const data = {
        seo_text: this.formDataToSend.seo_text,
        h1: this.formDataToSend.h1,
        url: this.formDataToSend.url,
        active: this.formDataToSend.active,
        noindex: this.formDataToSend.noindex,
        annotation: this.formDataToSend.annotation,
        title: this.formDataToSend.title,
        description: this.formDataToSend.description,
      };
      this.$store.dispatch("statics/createSeo", data);
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.SEO_LIST.replace(":page", 1),
      });
    },
    setSeoName(data) {
      // const value = urlTransform(data);
      this.formDataToSend.url = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-select {
  max-width: 300px;
}
.checkbox-container {
  @include scrollBar;

  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 20px;

  &_margin {
    margin-top: 20px;
  }
}
.checkbox-container__item {
  padding: 5px 0;
}
</style>
